import type { Prisma } from "@prisma/client";
import { stringToNumber } from "./stringTools";

export function convertPrismaDecimalToNumber(
  decimalValue: Prisma.Decimal
): number {
  /* v8 ignore next 3 */
  if (typeof decimalValue === "string") {
    return decimalValue;
  }
  // handle prisma.Decimals better?
  // values can look like this:
  // 123,45:
  // d: [123, 45]
  // s: 1
  // e: 2
  // 10.000.000
  // d: [1]
  // s: 1
  // e: 7
  let sign: number = decimalValue.s;
  let exp: number = decimalValue.e;
  // intVal: multiplicate with 10^exp, but only if exp is 7 or higher: https://mikemcl.github.io/decimal.js/#instance-properties
  // "The value of a Decimal is stored in a normalised base 10.000.000 floating point format."
  let intVal: number = decimalValue.d[0] * (exp >= 7 ? 10 ** exp : 1); //
  let fractInt: number = decimalValue.d.length > 1 ? decimalValue.d[1] : 0;
  let fractVal: number =
    fractInt === 0 ? 0 : fractInt / 10 ** fractInt.toString().length;
  let retVal = sign * (intVal + fractVal); // * 10 ** exp;
  return retVal;
}

export function printNumber(
  val: Prisma.Decimal | number | null,
  decimals: number,
  currency?: string | undefined
) {
  //  WTF i hass Prisma.Decimal
  // aus tradeproductshop, mit normalem defer und useLoader und Await, wäre product.Price ein string. Da kommt aber im Client dann immer ein Fehler, dass Prisma.Decimal kein React-Child ist.
  // Uncaught Error: Objects are not valid as a React child (found: [object Decimal]). If you meant to render a collection of children, use an array instead.
  // mit typeddefer, useTypedLoader und TypedAwait, ist der Typ laut VSCode immer Prisma.Decimal. Das fliegt mir aber hier teilweise mit
  // tools.tsx:126 Uncaught TypeError: Cannot read properties of undefined (reading '0')
  //  at convertPrismaDecimalToNumber (tools.tsx:126:39)
  //  at printAsEUR
  // also hab ich den Typ geprüft und das ist ein string. Also check ich jetzt hier auf string.
  // den Originalfehler sieht man noch in der Trade Overview. Aber nur beim Refresh, nicht beim normalen Navigieren!

  if (val === null) {
    return "";
  }

  let number: number;
  /* v8 ignore next 3 */
  if (typeof val === "string") {
    let tryParse = stringToNumber(val);
    if (tryParse) {
      number = tryParse;
    } else {
      return val;
    }
  }
  try {
    if (typeof val === "number") {
      number = val;
    } else {
      number = convertPrismaDecimalToNumber(val);
    }
    let formatter = new Intl.NumberFormat("de-DE", {
      style: currency ? "currency" : undefined,
      currency: currency,
      minimumFractionDigits: decimals,
      useGrouping: true, // Use grouping (comma separator)
    });

    return formatter.format(number);
    /* v8 ignore next 3 */
  } catch (e) {
    throw e;
  }
}

export function printAsEUR(val: Prisma.Decimal | number | null): string {
  return printNumber(val, 2, "EUR");
}

export function isValidNumber(stringValue: string): boolean {
  let numberValue = parseFloat(stringValue);
  // Check if the parsed value is a valid number and not NaN
  // Also, ensure that the input is not an empty string or contains only whitespaces
  return !isNaN(numberValue) && isFinite(numberValue) && /\S/.test(stringValue);
}

export function getValidNumber(stringValue: string): number | undefined {
  let numberValue = parseFloat(stringValue);
  // Check if the parsed value is a valid number and not NaN
  // Also, ensure that the input is not an empty string or contains only whitespaces
  if (!isNaN(numberValue) && isFinite(numberValue) && /\S/.test(stringValue)) {
    return numberValue;
  }
  return undefined;
}
