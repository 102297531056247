import type { Prisma } from "@prisma/client";
import { convertPrismaDecimalToNumber } from "./numberTools";

export function stringToNumber(numberString: string): number | null {
  let parsedNumber = parseFloat(numberString);
  if (isNaN(parsedNumber)) {
    return null;
  }
  return parsedNumber;
}

export function formatDecimalForInput(
  decimal: Prisma.Decimal | null | undefined
) {
  if (decimal) {
    let nr = convertPrismaDecimalToNumber(decimal);
    return formatNumberforInput(nr);
  }
  return "";
}

export function formatNumberforInput(number: number | null | undefined) {
  if (number) {
    return number.toString();
  }
  return "";
}

export function percentStringToNumber(percentString: string) {
  const match = percentString.match(/^["']?([\d.]+)%["']?$/);
  if (!match) {
    return -1;
    // throw new Error(`Invalid percentage format: "${input}"`);
  }
  return parseFloat(match[1]);
}

export function isEmpty(element: any) {
  return (
    element === undefined ||
    element === null ||
    element.toString().trim() === ""
  );
}

export function hasWhiteSpace(s: string) {
  return s.indexOf(" ") >= 0;
}
