import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import { useState, type JSX } from "react";
import { useTranslation } from "react-i18next";
import {
  Design,
  getOrientationForDiv,
  type ColumnDefinitionType,
  type ColumnType,
  type SortOrder,
} from "./TableSimpleDark";

type TableHeaderProps<T, K extends keyof T> = {
  columns: Array<ColumnDefinitionType<T, K>>;
  handleSorting: (
    sortField: any,
    sortOrder: "asc" | "desc",
    columnType?: ColumnType
  ) => any;
  design: Design;
  defaultSorting?: SortOrder<T>;
};

let TableHeader = <T, K extends keyof T>({
  columns,
  handleSorting,
  design,
  defaultSorting,
}: TableHeaderProps<T, K>): JSX.Element => {
  let { t } = useTranslation();

  const [sortField, setSortField] = useState<string>(
    (defaultSorting?.key as string) ?? ""
  );
  const [order, setOrder] = useState(defaultSorting?.order ?? "asc");

  const handleSortingChange = (key: string, columnType?: ColumnType) => {
    const sortOrder = key === sortField && order === "asc" ? "desc" : "asc";
    setSortField(key);
    setOrder(sortOrder);
    handleSorting(key, sortOrder, columnType);
  };

  if (design === Design.RoundedBackground) {
    return (
      <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
        <tr>
          {columns.map(({ key, header, sortable, columnType, orientation }) => {
            return (
              <th
                scope="col"
                className={`px-6 py-3`}
                key={key as string}
                onClick={
                  sortable
                    ? () => handleSortingChange(key as string, columnType)
                    : undefined
                }
              >
                <div className={`flex ${getOrientationForDiv(orientation)}`}>
                  {t(header)}
                  {sortable && order === "asc" && sortField === key && (
                    <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                  )}
                  {sortable && order === "desc" && sortField === key && (
                    <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
                  )}
                </div>
              </th>
            );
          })}
        </tr>
      </thead>
    );
  } else {
    return (
      <thead>
        <tr>
          {columns.map(({ key, header, sortable, columnType, orientation }) => {
            return (
              <th
                scope="col"
                className={`px-3 py-3.5 text-sm font-semibold text-black dark:text-white`}
                key={key as string}
                onClick={
                  sortable
                    ? () => handleSortingChange(key as string, columnType)
                    : undefined
                }
              >
                <div className={`flex ${getOrientationForDiv(orientation)}`}>
                  {t(header)}
                  {sortable && order === "asc" && sortField === key && (
                    <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                  )}
                  {sortable && order === "desc" && sortField === key && (
                    <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
                  )}
                </div>
              </th>
            );
          })}
        </tr>
      </thead>
    );
  }
};

export default TableHeader;
