import type { MouseEventHandler, JSX } from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { hasProperty } from "~/utils/tools/miscTools";
import Button from "../buttons/Button";
import TableHeader from "./TableSimpleDarkHeader";
import TableRows from "./TableSimpleDarkRows";

export type ColumnDefinitionType<T, K extends keyof T> = {
  key: K;
  header: string;
  width?: number;
  sortable: boolean;
  sortbyOrder?: "asc" | "desc";
  columnType?: ColumnType;
  orientation?: Orientation;
};

export enum Orientation {
  Left,
  Center,
  Right,
}

export enum ColumnType {
  Text,
  Number,
  Boolean,
  Date,
  Euro,
}

export type RowChild = {
  isVisible: boolean;
  content: React.ReactNode;
};

export type SortableData = {
  id: string; // element id per row - extend your ColumnDefinition if you need rowOnClick action!
  [key: string]: any;
  children?: RowChild[];
  additionalClassName?: string;
};

export enum Design {
  Simple,
  RoundedBackground,
}

export type SortOrder<T> = {
  key: keyof T;
  order: "asc" | "desc";
  columnType?: ColumnType;
};

type TableSimpleDarkProps<T, K extends keyof T> = {
  data: Array<T>;
  columnDefinition: Array<ColumnDefinitionType<T, K>>;
  buttonchildren?: React.ReactNode;
  buttonprops?: any;
  buttonPlacement?: "top" | "bottomcentered";
  buttononClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  buttontype?: "button" | "submit" | "reset" | undefined;
  tableTitle?: string;
  tableDescription?: string;
  design?: Design;
  rowOnClick?: (id: string) => void;
  currentRowID?: string;
  showFilterRow?: boolean;
};

function getDefaultSorting<T>(
  columns: ColumnDefinitionType<any, any>[]
): SortOrder<T> | undefined {
  let sortedColumns = columns.filter(
    (column) => column.sortbyOrder !== undefined
  );
  if (sortedColumns.length > 0) {
    if (sortedColumns.length > 1) {
      throw new Error(
        "more than 1 sorted columns are not possible! Dev Error!"
      );
    }
    return { key: sortedColumns[0].key, order: sortedColumns[0].sortbyOrder! };
  }
  // if nothing is given to sort -> just return the data entered
  else {
    return undefined;
  }
}

let TableSimpleDark = <T extends SortableData, K extends keyof T>({
  data,
  columnDefinition,
  buttonchildren,
  buttonprops,
  buttonPlacement = "top",
  buttononClick: buttonOnClick,
  buttontype,
  tableTitle,
  tableDescription,
  design = Design.Simple,
  rowOnClick,
  currentRowID,
  showFilterRow = true,
}: TableSimpleDarkProps<T, K>): JSX.Element => {
  let { t } = useTranslation();
  let [sortedTableData, setSortedTableData] = useState<T[]>(data);
  let defaultSortingResponse = getDefaultSorting<T>(columnDefinition);
  let [sortOrder, setSortOrder] = useState<SortOrder<T> | undefined>(
    defaultSortingResponse
  );
  let [filterCriteria, setFilterCriteria] = useState<
    { key: string; value: string }[]
  >([]);

  let filterSortTable = () => {
    let result = [...data];
    if (filterCriteria) {
      result = result.filter((row) => {
        let boolResult = true;
        filterCriteria.forEach((fc) => {
          if (`${row[fc.key as keyof T]}`) {
            let columnValue = row[fc.key as keyof T];
            if (hasProperty(columnValue, "value")) {
              columnValue = columnValue.value;
            }
            // TODOISSUE268: Filter for Date does not work, because the value is REALLY a date and the default to string prints it with a text-month.
            // So filtering for dates only works for day and year, not for month.
            // 18.12.2024 SE: I do not fix this today, as the text filter for dates is not efficient anyway.
            // waiting for feature 268 instead.
            let fcResult = String(columnValue).toLowerCase().includes(fc.value);
            boolResult = boolResult && fcResult;
          }
        });
        return boolResult;
      });
    }
    if (sortOrder) {
      result = result.sort((a, b) => {
        let aValue = a[sortOrder.key];
        let bValue = b[sortOrder.key];
        if (hasProperty(aValue, "value") && hasProperty(bValue, "value")) {
          aValue = aValue.value;
          bValue = bValue.value;
        }
        if (
          (aValue === null || aValue === undefined) &&
          (bValue === null || bValue === undefined)
        ) {
          return 0;
        }
        if (aValue === null || aValue === undefined) return 1;
        if (bValue === null || bValue === undefined) return -1;

        switch (sortOrder.columnType) {
          case ColumnType.Date:
            let aTime = new Date(aValue).getTime();
            let bTime = new Date(bValue).getTime();
            return (aTime - bTime) * (sortOrder.order === "asc" ? 1 : -1);
          case ColumnType.Boolean:
            let aBool = aValue === true || aValue === "true";
            let bBool = bValue === true || bValue === "true";
            return (
              (aBool === bBool ? 0 : aBool ? -1 : 1) *
              (sortOrder.order === "asc" ? 1 : -1)
            );
          case ColumnType.Euro:
          case ColumnType.Number:
            let aNumber = parseFloat(aValue as unknown as string);
            let bNumber = parseFloat(bValue as unknown as string);
            return (aNumber - bNumber) * (sortOrder.order === "asc" ? 1 : -1);
          case ColumnType.Text:
          default: {
            const aString = aValue.toString();
            const bString = bValue.toString();
            return (
              aString.localeCompare(bString, "en", { numeric: true }) *
              (sortOrder.order === "asc" ? 1 : -1)
            );
          }
        }
      });
    }
    setSortedTableData(result);
  };

  let handleSorting = (
    sortField: keyof T,
    sortOrder: "asc" | "desc",
    columnType?: ColumnType
  ) => {
    if (sortField) {
      setSortOrder({
        key: sortField,
        order: sortOrder,
        columnType: columnType,
      });
    }
  };

  useEffect(() => {
    filterSortTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnDefinition, filterCriteria, sortOrder, data]);

  let FilterRows: K[] = columnDefinition.map((column) => {
    return column.key;
  });

  let handleFilter =
    (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      let value = event.target.value.toLowerCase();
      let tmpFilterCriteria = [...filterCriteria];
      let elem = tmpFilterCriteria.find((element) => element.key === key);
      if (elem) {
        if (value) {
          elem.value = value;
        } else {
          let index = tmpFilterCriteria.indexOf(elem, 0);
          tmpFilterCriteria.splice(index, 1);
        }
      } else if (value) {
        tmpFilterCriteria.push({ key, value });
      }
      setFilterCriteria(tmpFilterCriteria);
    };

  if (design === Design.RoundedBackground) {
    return (
      <>
        {buttonchildren && buttonPlacement === "top" && (
          <div className="mb-2">
            <Button onClick={buttonOnClick} type={buttontype}>
              {buttonchildren}
            </Button>
          </div>
        )}
        {/* scrollbar with darkmode */}
        <div
          className="relative overflow-x-auto
   [&::-webkit-scrollbar]:w-2
  [&::-webkit-scrollbar-track]:bg-gray-100
  [&::-webkit-scrollbar-thumb]:bg-gray-300
  dark:[&::-webkit-scrollbar-track]:bg-gray-700
  dark:[&::-webkit-scrollbar-thumb]:bg-gray-500
        shadow-md sm:rounded-lg"
        >
          <table className="table-auto w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <TableHeader
              columns={columnDefinition}
              handleSorting={handleSorting}
              design={design}
              defaultSorting={defaultSortingResponse}
            />
            <TableRows
              data={sortedTableData}
              columns={columnDefinition}
              filterRows={FilterRows}
              handleFilter={handleFilter}
              design={design}
              rowOnClick={rowOnClick}
              currentRowID={currentRowID}
              showFilterRow={showFilterRow}
            />
          </table>
        </div>

        {buttonchildren && buttonPlacement === "bottomcentered" && (
          <div className="w-full flex justify-center mt-2">
            <Button onClick={buttonOnClick} type={buttontype}>
              {buttonchildren}
            </Button>
          </div>
        )}
      </>
    );
  } else {
    let isMarginTop =
      tableTitle ||
      tableDescription ||
      (buttonchildren && buttonPlacement === "top");
    return (
      <div className="bg-bg-light dark:bg-bg-dark">
        <div className="mx-auto max-w-7xl">
          <div className="bg-bg-light dark:bg-bg-dark">
            <div className="px-4 sm:px-6 lg:px-8">
              {isMarginTop && (
                <div className="sm:flex sm:items-center mb-8 pt-10">
                  {(tableTitle || tableDescription) && (
                    <div className="sm:flex-auto">
                      {tableTitle && (
                        <h1 className="text-base font-semibold leading-6 text-black dark:text-white">
                          {t(tableTitle)}
                        </h1>
                      )}
                      {tableDescription && (
                        <p className="mt-2 text-sm text-gray-300">
                          {t(tableDescription)}
                        </p>
                      )}
                    </div>
                  )}

                  {buttonchildren && buttonPlacement === "top" && (
                    <div className="mt-4 sm:mt-0 sm:flex-none">
                      <button
                        className="block rounded-md bg-indigo-500 px-3 py-2 text-center text-sm font-semibold text-black dark:text-white hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                        {...buttonprops}
                        onClick={buttonOnClick}
                        type={buttontype}
                      >
                        {buttonchildren}
                      </button>
                    </div>
                  )}
                </div>
              )}
              <div className="flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table className="min-w-full divide-y divide-gray-700">
                      <TableHeader
                        columns={columnDefinition}
                        handleSorting={handleSorting}
                        design={design}
                        defaultSorting={defaultSortingResponse}
                      />
                      <TableRows
                        key={`simpleTableRows`}
                        data={sortedTableData}
                        columns={columnDefinition}
                        filterRows={FilterRows}
                        handleFilter={handleFilter}
                        design={design}
                        showFilterRow={showFilterRow}
                      />
                    </table>
                    <div className="text-center text-text-light dark:text-text-dark mt-4">
                      {data.length === 0 && <h1>{t("noResult")}</h1>}
                      {!sortedTableData.length && data.length > 0 && (
                        <h1>{t("noResultFilter")}</h1>
                      )}
                    </div>
                    {buttonchildren && buttonPlacement === "bottomcentered" && (
                      <div className="w-full flex justify-center mt-2">
                        <Button
                          {...buttonprops}
                          onClick={buttonOnClick}
                          type={buttontype}
                        >
                          {buttonchildren}
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export function getOrientationForText(orientation: Orientation | undefined) {
  if (orientation === undefined) {
    return "";
  }

  switch (orientation) {
    case Orientation.Left:
      return "text-left";
    case Orientation.Right:
      return "text-right";
    case Orientation.Center:
      return "text-center";
    default:
      throw new Error(`orientation ${orientation} is not supported`);
  }
}

export function getOrientationForDiv(orientation: Orientation | undefined) {
  if (orientation === undefined) {
    return "";
  }

  switch (orientation) {
    case Orientation.Left:
      return "justify-left";
    case Orientation.Right:
      return "justify-end";
    case Orientation.Center:
      return "justify-center";
    default:
      throw new Error(`orientation ${orientation} is not supported`);
  }
}

export default TableSimpleDark;
