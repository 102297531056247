import { CheckIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";
import { printSafeDateString } from "~/utils/tools/dateTools";
import { hasProperty } from "~/utils/tools/miscTools";
import { printAsEUR, printNumber } from "~/utils/tools/numberTools";
import {
  ColumnType,
  Design,
  getOrientationForText,
  type ColumnDefinitionType,
  type SortableData,
} from "./TableSimpleDark";

import type { JSX } from "react";

type TableRowsProps<T extends SortableData, K extends keyof T> = {
  data: Array<T>;
  columns: Array<ColumnDefinitionType<T, K>>;
  filterRows: K[];
  handleFilter: (
    columnKey: string
  ) => React.ChangeEventHandler<HTMLInputElement>;
  design: Design;
  rowOnClick?: (id: string) => void;
  currentRowID?: string;
  showFilterRow: boolean;
};

let TableRows = <T extends SortableData, K extends keyof T>({
  data,
  columns,
  filterRows,
  handleFilter,
  design,
  rowOnClick,
  currentRowID,
  showFilterRow,
}: TableRowsProps<T, K>): JSX.Element => {
  let { t } = useTranslation();
  if (design === Design.RoundedBackground) {
    let filterRow = <></>;
    if (showFilterRow) {
      filterRow = (
        <tr
          key="filterRow"
          className={`border-b dark:border-gray-700 bg-tablerow-light dark:bg-tablerow-dark`}
        >
          {columns.map((column, index) => {
            // Check if the column's key is in the filterRows array
            if (
              filterRows.includes(column.key as K) &&
              column.columnType !== undefined
            ) {
              let width = "w-full";
              // 20.11.2024 SE: removed, as the table gets horizontal scroll bars with fixed widths
              // if (column.width) {
              //   width = `w-${column.width}`;
              // } else if (column.columnType === ColumnType.Date) {
              //   width = "w-32";
              // } else if (column.columnType === ColumnType.Euro) {
              //   width = "min-w-10 max-w-40";
              // }
              return (
                <td
                  key={`filter-cell-${index}`}
                  className={`${getOrientationForText(column.orientation)}`}
                >
                  <input
                    type="text"
                    className={`${width} text-sm text-text-light dark:text-text-dark bg-tablerow-light dark:bg-tablerow-dark`}
                    placeholder={`Filter ${t(String(column.header))}`}
                    onChange={handleFilter(column.key as string)}
                    id={`${String(column.key)}-${index}`}
                    key={`${String(column.key)}-${index}`}
                    // Add an onChange handler here to handle filtering if needed
                  />
                </td>
              );
            }
            // If the column's key is not in filterRows, render an empty cell
            return <td key={`filter-cell-${index}`}></td>;
          })}
        </tr>
      );
    }
    let rows = data.map((row, index) => {
      return (
        <tr
          key={`row-${row.id}-${index}`}
          onClick={() => {
            if (rowOnClick && row.id) {
              rowOnClick(row.id);
            }
          }}
          className={`border-b dark:border-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600 transition duration-1000 ${
            currentRowID === row.id
              ? "bg-gray-200 dark:bg-gray-600"
              : "bg-tablerow-light dark:bg-tablerow-dark"
          } `}
          aria-label={`element-${row.id}`}
        >
          {columns.map((column, index2) => {
            let element = getReactElementForType(
              row[column.key],
              column.columnType
            );
            return (
              <td
                className={`px-6 py-2 ${getOrientationForText(
                  column.orientation
                )}`}
                key={`cell-${row.id}-${index2}`}
              >
                {element}
              </td>
            );
          })}
        </tr>
      );
    });

    return (
      <tbody>
        {filterRow}
        {rows}
      </tbody>
    );
  } else {
    let filterRow = <></>;
    if (showFilterRow) {
      filterRow = (
        <tr key="filterRow">
          {columns.map((column, index) => {
            // Check if the column's key is in the filterRows array
            if (filterRows.includes(column.key as K)) {
              if (column.columnType !== undefined) {
                return (
                  <td
                    key={`filter-cell-${index}`}
                    className={`${getOrientationForText(column.orientation)}`}
                  >
                    <input
                      type="text"
                      className="w-full px-3 text-sm text-text-light dark:text-text-dark bg-bg-light dark:bg-bg-dark"
                      placeholder={`${t("filter")} ${t(String(column.header))}`}
                      onChange={handleFilter(column.key as string)}
                      id={`${String(column.key)}`}
                      key={`${String(column.key)}`}
                      // Add an onChange handler here to handle filtering if needed
                    />
                  </td>
                );
              } else {
                return (
                  <td key={`filter-cell-${index}`} className={`min-w-0`}>
                    {/* empty */}
                  </td>
                );
              }
            }
            // If the column's key is not in filterRows, render an empty cell
            return <td key={`filter-cell-${index}`}></td>;
          })}
        </tr>
      );
    }

    let rows = data.map((row, index) => {
      return (
        <>
          <tr
            key={`row-${row.id}-${index}`}
            className={`border-b-2 border-b-gray-300 dark:border-b-gray-800 ${
              currentRowID === row.id && "bg-button-light dark:bg-button-dark"
            } ${row.additionalClassName}`}
          >
            {columns.map((column, index2) => {
              let element = getReactElementForType(
                row[column.key],
                column.columnType
              );
              return (
                <td
                  className={`px-3 py-4 text-sm text-text-light dark:text-text-dark ${getOrientationForText(
                    column.orientation
                  )}`}
                  key={`cell-${row.id}-${index2}`}
                >
                  {element}
                </td>
              );
            })}
          </tr>
          {row.children &&
            row.children.map(
              (rowChild, index) =>
                rowChild.isVisible && (
                  <tr key={`rowChild-${row.id}-${index}`}>
                    <td colSpan={columns.length}>{rowChild.content}</td>
                  </tr>
                )
            )}
        </>
      );
    });

    return (
      // Do not use divide here, as it interferes with the bordercolors of the rows!
      //   <tbody className="divide-y divide-gray-800">
      (<tbody>
        {filterRow}
        {rows}
      </tbody>)
    );
  }
};

function getReactElementForType(
  colVal: any,
  columnType?: ColumnType
): React.ReactNode {
  if (hasProperty(colVal, "content")) {
    return colVal.content;
  }
  if (hasProperty(colVal, "value")) {
    colVal = colVal.value;
  }
  let element: React.ReactNode;
  switch (columnType) {
    case ColumnType.Boolean:
      let boolVal = colVal as boolean;
      switch (boolVal) {
        case true:
          element = (
            <CheckIcon className="h-6 w-6 text-green-500 hover:animate-pulse hover:cursor-pointer" />
          );
          break;
        case false:
          element = (
            <XMarkIcon className="h-6 w-6 text-red-500 hover:animate-pulse hover:cursor-pointer" />
          );
          break;
        case undefined:
          element = (
            <XMarkIcon className="h-6 w-6 text-gray-500 hover:animate-pulse hover:cursor-pointer" />
          );
          break;
      }
      break;
    case ColumnType.Number:
      element = printNumber(colVal as number, 2);
      break;
    case ColumnType.Euro:
      element = printAsEUR(colVal as number);
      break;
    case ColumnType.Date:
      element = printSafeDateString(colVal as Date);
      break;
    case ColumnType.Text:
    default:
      element = colVal;
      break;
  }
  return element;
}

export default TableRows;
